/*
* decaffeinate suggestions:
* DS102: Remove unnecessary code created because of implicit returns
* Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
*/
import "summernote";
const sendFile = function(file, toSummernote) {
  const data = new FormData;
  data.append('upload[image]', file);
  return $.ajax({
    data,
    type: 'POST',
    url: '/uploads',
    cache: false,
    contentType: false,
    processData: false,
    success(data) {
      const img = document.createElement('IMG');
      img.src = data.url;
      img.setAttribute('id', `sn-image-${data.upload_id}`);
      return toSummernote.summernote('insertNode', img);
    }
  });
};

const deleteFile = file_id => $.ajax({
  type: 'DELETE',
  url: `/uploads/${file_id}`,
  cache: false,
  contentType: false,
  processData: false
});

$(document).on('turbolinks:load', () => $('[data-provider="summernote"]').each(function() {
  return $(this).summernote({
    lang: 'ja-JP',
    height: 400,
    fontNames: ["YuGothic","Yu Gothic","Hiragino Kaku Gothic Pro","Meiryo","sans-serif", "Arial","Arial Black","Comic Sans MS","Courier New","Helvetica Neue","Helvetica","Impact","Lucida Grande","Tahoma","Times New Roman","Verdana"],
    fontsize: '16',
    callbacks: {
      onImageUpload(files) {
        return sendFile(files[0], $(this));
      },
      onMediaDelete(target, editor, editable) {
        const upload_id = target[0].id.split('-').slice(-1)[0];
        if (!!upload_id) {
          deleteFile(upload_id);
        }
        return target.remove();
      }
    }
  });
}));
